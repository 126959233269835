import * as React from "react";
import List from "@mui/material/List";
import DocumentItem from "./DocumentItem";

export default function DocumentsList() {
    const [data, setData] = React.useState([]);
    const documentsUrl = "https://cardiologia-strapi.fly.dev";

    React.useEffect(() => {
            fetch(documentsUrl + "/api/upload/files?sort[0]=name:desc", {
              method: "GET",
              headers: {
                Authorization:
                  "Bearer f19f04f44d3a73adac17e7ae0795a01d013bc471bb5e6df1a1e0df30b1e01d642a03656f94db368d29167ace7c2007b2d331d5e259cfabdb5ab5d6a71f3e8503c2f85f7e194d44ae82c992bf18a27debc53138143556e56966d4f48028aae0da97c836361cd9de80c20b38e9bf7bd9c189df2e5fbebc6d44e73470aecc6c1d8f",
              },
            })
              .then((response) => response.json())
              .then((data) => {
                setData(data);
              });
    }, []);

  return (
    <List sx={{ flexGrow: 1 }}>
      {data.map((document) => (
        <DocumentItem
          data={document}
          key={document.id}
          documentsUrl={documentsUrl}
        />
      ))}
    </List>
  );
}
