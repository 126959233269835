import * as React from 'react';
import Box from "@mui/material/Box";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import TopBar from "./components/Navigation/TopBar";
import DocumentsList from "./components/Documents/DocumentsList";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import './App.css';

const darkTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#000000",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
    <Box sx={{ flexGrow: 1 }}>
      <TopBar/>
      <DocumentsList />
    </Box>
    </ThemeProvider>
  );
}

export default App;
