import * as React from "react";
import Link from "@mui/material/Link";

import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Divider from "@mui/material/Divider";

export default function DocumentItem(props) {
  const documentLink = props.documentsUrl + props.data.url;
  return (
    <Link href={documentLink} underline="none" target="_blank" rel="noopener">
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <PictureAsPdfIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={props.data.caption ?? props.data.name}
          secondary={props.data.name}
        />
      </ListItem>
      <Divider />
    </Link>
  );
}
